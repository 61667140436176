import { Button, Spinner, Flex, Box, Heading, Text } from '@chakra-ui/react'
import { darken } from '@chakra-ui/theme-tools'
import { GetServerSideProps } from 'next'
import { useRouter } from 'next/dist/client/router'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  useGetAuthorizationQuery,
  useStartLoginQuery,
} from 'lib/graphql/generated/hooks'
import { restrictRoute, useAuth } from 'modules/auth'
import { Layout } from 'modules/layout'

const _START_LOGIN = /* GraphQL */ `
  query startLogin($redirectTo: String!) {
    getAuthorizeUrl(redirectUri: $redirectTo)
  }
`

const _AUTHORIZE = /* GraphQL */ `
  query getAuthorization($callbackUri: String!) {
    getAuthorization(callbackUri: $callbackUri) {
      accessToken
      expiresAt
      tokenType
    }
  }
`

const LoginPage = (): JSX.Element | null => {
  const {
    query: { code },
  } = useRouter()
  const [location, setLocation] = useState<Location | null>(null)

  useEffect(function setUrl() {
    setLocation(window.location)
  }, [])

  if (!location) {
    return null
  }

  return (
    <Layout layout="centered" hideMenu>
      {!!code && typeof code === 'string' ? (
        <Authorize redirectUrl={location.href} />
      ) : (
        <Login redirectUrl={`${location.origin}${location.pathname}`} />
      )}
    </Layout>
  )
}

const OfficeIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3817 17.5245C11.4122 17.3323 11.42 3.43884 11.391 3.13566C9.03027 3.69797 6.67302 4.25978 4.3061 4.82359V14.6174C3.08754 15.0937 1.87332 15.5681 0.65959 16.0419C0.651776 16.0375 0.645078 16.0351 0.638752 16.0306C0.633418 16.0267 0.626596 16.0208 0.6261 16.0155C0.622254 15.9788 0.617417 15.9421 0.617417 15.9049C0.616921 11.9476 0.61692 7.98977 0.61692 4.04221C0.67125 3.97524 0.741456 3.96685 0.802484 3.94444C4.27174 2.6452 7.74199 1.34733 11.2116 0.0482121C11.3318 0.00314713 11.4408 -0.0188847 11.5741 0.020298C13.4778 0.572844 15.3834 1.119 17.2886 1.66717C17.3162 1.67555 17.3439 1.68532 17.3831 1.69796V18.3777C16.8361 18.5298 16.2939 18.6819 15.7512 18.8315C14.3698 19.2124 12.9875 19.5905 11.6071 19.9743C11.4719 20.012 11.3536 20.0091 11.2208 19.9601C7.80835 18.6991 4.39429 17.4419 0.981348 16.1842C0.913995 16.1593 0.848006 16.1314 0.79132 16.0713C4.32161 16.5559 7.85189 17.0399 11.3817 17.5245Z"
        fill="currentColor"
      />
    </svg>
  )
}

const Login = ({ redirectUrl }: { redirectUrl: string }): JSX.Element => {
  const { formatMessage } = useIntl()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const { data, isLoading, isError } = useStartLoginQuery(
    { redirectTo: redirectUrl },
    { enabled: !isRedirecting, staleTime: 30 * 60 * 1000 }
  )

  const handleStartLogin = () => {
    if (data?.getAuthorizeUrl) {
      setIsRedirecting(true)
      window.location.href = data.getAuthorizeUrl
    }
  }

  return (
    <Box p="4" minWidth="80" layerStyle="card">
      <Text as="p" fontSize="lg">
        {formatMessage({ id: 'login.welcome' })}
      </Text>
      <Heading as="h1" mb="10" size="lg">
        {formatMessage({ id: 'login.title' })}
      </Heading>
      {isError ? (
        <>
          <Text color="red.500">
            {formatMessage({ id: 'global.somethingWentWrong' })}{' '}
          </Text>
          <Text color="red.500" mb="4">
            {formatMessage({ id: 'global.tryAgainOrContactSupport' })}
          </Text>
        </>
      ) : (
        <Button
          w="100%"
          bg="#DC3E15"
          _hover={{
            bg: darken('#DC3E15', 8)({}),
          }}
          _active={{
            bg: darken('#DC3E15', 16)({}),
          }}
          color="white"
          size="lg"
          leftIcon={<OfficeIcon />}
          isLoading={isLoading || isRedirecting}
          isDisabled={isLoading || isRedirecting}
          onClick={() => handleStartLogin()}
        >
          {formatMessage({ id: 'login.365' })}
        </Button>
      )}
    </Box>
  )
}

const Authorize = ({ redirectUrl }: { redirectUrl: string }): JSX.Element => {
  const { formatMessage } = useIntl()
  const { push } = useRouter()
  const { login, isLoggedIn } = useAuth()
  const { isError, error } = useGetAuthorizationQuery(
    {
      callbackUri: redirectUrl,
    },
    {
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !isLoggedIn,
      onSuccess: (data) => {
        if (data?.getAuthorization.accessToken) {
          login({
            accessToken: data.getAuthorization.accessToken,
            expiresAt: data.getAuthorization.expiresAt,
            tokenType: data.getAuthorization.tokenType,
          })
          push('/appointments')
        }
      },
    }
  )

  if (isError) {
    const noClinicError =
      error &&
      error.response?.errors?.find((err) => {
        return err.extensions.code === 'USER_HAS_NO_ACCESS_TO_ANY_CLINIC'
      })

    return (
      <Flex
        textAlign="center"
        flexDir="column"
        alignItems="center"
        color="red.500"
        maxW="xl"
      >
        {noClinicError ? (
          <>
            <Text as="p" fontSize="lg">
              {formatMessage({ id: 'login.noAccess' })}
            </Text>
            <Heading as="h1" mb="10" size="lg">
              {formatMessage(
                { id: 'login.userHasNoClinics' },
                { email: noClinicError.extensions.email || 'n/a' }
              )}
            </Heading>
          </>
        ) : (
          <>
            <Text as="p" fontSize="lg">
              {formatMessage({ id: 'global.somethingWentWrong' })}
            </Text>
            <Heading as="h1" mb="10" size="lg">
              {formatMessage({ id: 'login.loginFail' })}
            </Heading>
          </>
        )}
        <Button size="lg" onClick={() => push('/login')}>
          {formatMessage({ id: 'login.backToLogin' })}
        </Button>
      </Flex>
    )
  }

  return (
    <Spinner thickness="4px" size="lg" emptyColor="gray.200" color="blue.500" />
  )
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  return restrictRoute(ctx, (session) => {
    return session ? '/appointments' : undefined
  })
}

export default LoginPage
